import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import styles from "!!raw-loader!../medicalInfo/[id]/index.module.css";
import useGetPages from "@base/modules/wpApis/hooks/useGetPages";
import CommonStyles from "@components/CommonStyles";
import { responseUI } from "@base/themes/response";
import { initialPage } from "@base/themes/colorTheme";
import { Page } from "@base/types/wp";

const StyledReactQuill = styled(ReactQuill)`
  ${styles}
`;

export default function About() {
  //! state
  const { data, error, isLoading, refetch } = useGetPages({ _embed: true, page: 1, per_page: 1, slug: "gioi-thieu" });
  const [about, setAbout] = useState<Page>();

  //! function
  useEffect(() => {
    if (data?.data.length) {
      const _post = data?.data[0] ?? {};
      setAbout(_post);
    }
  }, [data]);

  //! render
  return (
    <div className="relative min-h-[300px] py-5">
      <CommonStyles.SpinnerUI isSpinning={isLoading} />

      <StyledReactQuill>
        <div className="bg-common-background wp-embed-content">
          <div className={`${responseUI.screen} ${initialPage.paddingX} max-[700px]:px-2`}>
            <div dangerouslySetInnerHTML={{ __html: about?.content?.rendered ?? "" }}></div>
          </div>
        </div>
      </StyledReactQuill>
    </div>
  );
}
