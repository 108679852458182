import { categories } from "@base/constants";
import MedicalManualLayout from "@base/layouts/MedicalManual/MedicalManualLayout";
import useGetPosts from "@base/modules/wpApis/hooks/useGetPosts";
import { WPPostsFilter } from "@base/modules/wpApis/interface";
import { responseUI } from "@base/themes/response";
import { Post, PostsCategory } from "@base/types/wp";
import CommonStyles from "@components/CommonStyles";
import CardArticle from "@components/CommonStyles/CardArticle";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function MedicalInfo() {
  //! state
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<WPPostsFilter>({
    _embed: true,
    page: searchParams.get("page") ? Number(searchParams.get("page")) : 1,
    per_page: searchParams.get("per_page") ? Number(searchParams.get("per_page")) : 6,
    categories: searchParams.get("categories") ? [Number(searchParams.get("categories"))] : undefined,
  });
  const { data, error, isLoading, refetch } = useGetPosts(filter, false);

  //! function
  useEffect(() => {
    setSearchParams({
      page: filter.page ? filter.page.toString() : "1",
      per_page: filter.per_page ? filter.per_page.toString() : "6",
      categories: filter.categories?.length ? filter.categories[0].toString() : "",
    });
    refetch();
  }, [filter]);

  const renderArticleInfo = (posts: Post[]) => {
    return posts.map((post, index) => {
      const imgLink =
        post._embedded &&
        post._embedded["wp:featuredmedia"] &&
        post._embedded["wp:featuredmedia"].length &&
        post._embedded["wp:featuredmedia"][0] &&
        post._embedded["wp:featuredmedia"][0].source_url
          ? post._embedded["wp:featuredmedia"][0].source_url
          : "";
      return (
        <CardArticle title={post.title?.rendered} content={post.excerpt.rendered} date={post.date} imgLink={imgLink} slug={post.slug} key={index} />
      );
    });
  };

  const onSelectCategory = (item: PostsCategory) => {
    setFilter((prev) => ({ ...prev, categories: item.id && Number(item.id) > 0 ? [item.id] : undefined, page: 1 }));
  };

  const handleChangePage = (value: number) => {
    setFilter((prev) => ({ ...prev, page: value }));
  };

  //! render
  const header = () => {
    return (
      <div className="max-w-[800px] flex flex-col h-full justify-between">
        <div>
          <CommonStyles.TypographyUI use="title" level={3} className={`${responseUI.text.title}`}>
            Thông tin y tế
          </CommonStyles.TypographyUI>
          <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext text-base break-words">
            {`Nắm bắt nhanh chóng các thông tin y tế chính xác, tham khảo trải nghiệm khám chữa bệnh từ các người dùng khác.`}
          </CommonStyles.TypographyUI>
        </div>
      </div>
    );
  };

  return (
    <MedicalManualLayout
      Header={header()}
      Content={
        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-8 relative min-h-[408.84px]">
          <CommonStyles.SpinnerUI isSpinning={isLoading} />

          {renderArticleInfo(data?.data ?? [])}

          {data && data.data.length > 0 ? (
            <div className="col-span-2 max-sm:col-span-1 flex justify-center">
              <CommonStyles.PaginationUI
                current={filter.page}
                pageSize={filter.per_page}
                total={data?.headers["x-wp-total"] ? Number(data?.headers["x-wp-total"]) : 0}
                onChange={handleChangePage}
              />
            </div>
          ) : null}
        </div>
      }
      onSelectCategory={onSelectCategory}
    />
  );
}
